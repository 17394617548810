import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/functions";

const config = {
  apiKey: "AIzaSyBvVB8EHzW_4-brI9T37yGaSFBHAMZqJtE",
  authDomain: "redsoapp.firebaseapp.com",
  databaseURL: "https://redsoapp.firebaseio.com",
  projectId: "redsoapp",
  storageBucket: "redsoapp.appspot.com",
  messagingSenderId: "691782620732",
  appId: "1:691782620732:web:30b1a79fced647fe0dd539",
};

firebase.initializeApp(config);

export const db = firebase.firestore();

export const auth = () => firebase.auth();

export const functions = firebase.functions();
