import { Formik, Field, Form } from "formik";
import { auth } from "./db";
import { navigate } from "@reach/router";

function Login() {
  const submit = async (email) => {
    const actionCodeSettings = {
      url: "https://www.redsoapp.com/finishSignUp",
      handleCodeInApp: true,
    };

    await auth().sendSignInLinkToEmail(email, actionCodeSettings);
    window.localStorage.setItem("emailForSignIn", email);
    navigate("/postLogin");
  };

  return (
    <>
      <div className="container mx-auto py-4 px-4 items-center justify-center flex flex-1 lg:px-8">
        <Formik
          initialValues={{ email: "" }}
          onSubmit={async (values) => {
            try {
              await submit(values.email);
            } catch (e) {}
          }}
        >
          <Form className="border-2 border-red p-8 text-center w-full lg:w-1/2">
            <label htmlFor="email" className="block text-left text-red">
              Email
            </label>
            <Field name="email" className="w-full border-b-2" type="email" />
            <button type="submit" className="bg-red mt-4 px-4 py-2 text-white">
              Login
            </button>
          </Form>
        </Formik>
      </div>
    </>
  );
}

export default Login;
