import React, { useEffect, useState } from "react";
import QRCode from "qrcode.react";
import { db } from "./db";
import { Link } from "@reach/router";

function Builds({ projectId }) {
  const [builds, setBuilds] = useState([]);
  const [error, setError] = useState(null);
  useEffect(() => {
    return db
      .collection(`/apps/${projectId}/builds`)
      .orderBy("createdAt", "desc")
      .limit(10)
      .onSnapshot({
        error: (error) => {
          setError(error);
        },
        next: (snapshot) => {
          const data = [];
          setError(null);
          snapshot.forEach((s) => {
            data.push({
              ...s.data(),
              id: s.id,
            });
          });
          setBuilds(data);
        },
      });
  }, [projectId]);

  if (error) {
    return (
      <>
        <div className="flex-1">
          <div className="bg-blue py-8 text-white px-4 lg:px-0">
            <div className="flex flex-row items-center container mx-auto lg:px-8">
              <QRCode
                size={64}
                bgColor="#00000000"
                fgColor="#FFFFFF"
                className="ml-auto"
                value={window.location.href}
              />
            </div>
          </div>
          <div className="container mx-auto py-4 px-4 lg:px-8">
            Permission Denied
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <div className="flex-1">
        <div className="bg-blue py-8 text-white px-4 lg:px-0">
          <div className="flex flex-row items-center container mx-auto lg:px-8">
            <QRCode
              size={64}
              bgColor="#00000000"
              fgColor="#FFFFFF"
              className="ml-auto"
              value={window.location.href}
            />
          </div>
        </div>
        <div className="container mx-auto py-4 px-4 lg:px-8">
          {builds.map((build) => (
            <Link
              key={build.id}
              className="flex flex-row items-center shadow p-4 mb-4"
              to={`/${projectId}/builds/${build.id}`}
            >
              <img
                className="mr-2 w-16 h-16"
                src={build.icon}
                alt={build.name}
              />
              <div>
                <h1 className="text-2xl font-medium">
                  {build.appName} ({build.version})
                </h1>
                <p>for {build.platform} by RedSo</p>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </>
  );
}

export default Builds;
