import React, { createContext, useContext, useEffect, useState } from "react";
import { auth } from "./db";

const Context = createContext();

export function AuthStateProvider({ children }) {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  useEffect(() => {
    auth().onAuthStateChanged((user) => {
      setUser(user);
      setLoading(false);
    });
  });

  return <Context.Provider value={{ user, loading }} children={children} />;
}

export function useAuthState() {
  return useContext(Context);
}
