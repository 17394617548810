import React, { useEffect, useState } from "react";
import QRCode from "qrcode.react";
import { utcToZonedTime, format } from "date-fns-tz";
import ReactMarkdown from "react-markdown";
import { db } from "./db";

function IOSBuild({ build, projectId }) {
  return (
    <>
      <div className="flex flex-col items-stretch mb-4 w-full lg:flex-row lg:items-start">
        <div className="flex-1 overflow-scroll">
          <p>
            <strong className="font-bold">App ID:</strong> {build.info.appId}
          </p>
          <p>
            <strong className="font-bold">Version:</strong>{" "}
            {build.info.versionName} ({build.info.versionCode})
          </p>
          <p>
            <strong className="font-bold">Minimum OS Version:</strong>{" "}
            {build.info.minOS}
          </p>
          <p>
            <strong className="font-bold">SDK:</strong> {build.info.buildSDK}
          </p>

          <p className="text-gray-700">
            <strong className="font-bold">Created At:</strong>{" "}
            {format(
              utcToZonedTime(build.createdAt.toDate(), "Asia/Hong_Kong"),
              "MMM dd, yyyy 'at' HH:mm",
              { timeZone: "Asia/Hong_Kong" }
            )}
          </p>
          <p className="text-gray-700">
            <strong className="font-bold">App Size:</strong>{" "}
            {((build.size || 0) / 1000 / 1000).toFixed(2)} MB
          </p>
        </div>

        <QRCode
          size={128}
          className="ml-auto hidden lg:block"
          value={window.location.href}
        />
      </div>
      <div className="mb-2 overflow-scroll">
        <h3 className="font-bold">Environment:</h3>
        <ReactMarkdown>{build.env}</ReactMarkdown>
      </div>
      <div className="mb-2">
        <h3 className="font-bold">Mobile Provision</h3>
        <p>
          Name: {build.info.mobileProvision.name} (
          {build.info.mobileProvision.uuid})
        </p>
        <p>Team: {build.info.mobileProvision.teamName}</p>
        <p>
          Expiration Date:{" "}
          {format(
            utcToZonedTime(
              new Date(build.info.mobileProvision.expirationDate.toDate()),
              "Asia/Hong_Kong"
            ),
            "MMM dd, yyyy 'at' HH:mm",
            { timeZone: "Asia/Hong_Kong" }
          )}
        </p>
        {build.info.mobileProvision.provisionsAllDevices && "Enterprise Build"}
        {build.info.mobileProvision.provisionedDevices && (
          <>
            <p>Eligible Devices: </p>
            <ol>
              {build.info.mobileProvision.provisionedDevices.map((i) => (
                <li key={i}>{i}</li>
              ))}
            </ol>
          </>
        )}
      </div>
    </>
  );
}

function AndroidBuild({ build }) {
  return (
    <>
      <div className="flex items-stretch mb-4 w-full flex-row lg:items-start">
        <div className="flex-1 overflow-scroll">
          <p>
            <strong className="font-bold">App ID:</strong> {build.info.appId}
          </p>
          <p>
            <strong className="font-bold">Version:</strong>{" "}
            {build.info.versionName} ({build.info.versionCode})
          </p>
          <p>
            <strong className="font-bold">Minimum OS Version:</strong>{" "}
            {build.info.minOS}
          </p>
          <p>
            <strong className="font-bold">SDK:</strong> {build.info.buildSDK}
          </p>
          <p className="text-gray-700">
            <strong className="font-bold">Created At:</strong>{" "}
            {format(
              utcToZonedTime(
                new Date(build.createdAt.toDate()),
                "Asia/Hong_Kong"
              ),
              "MMM dd, yyyy 'at' HH:mm",
              { timeZone: "Asia/Hong_Kong" }
            )}
          </p>
          <p className="text-gray-700">
            <strong className="font-bold">App Size:</strong>{" "}
            {((build.size || 0) / 1000 / 1000).toFixed(2)} MB
          </p>
        </div>

        <QRCode
          size={128}
          className="ml-auto hidden lg:block"
          value={window.location.href}
        />
      </div>
      <div className="mb-2 overflow-scroll">
        <h3 className="font-bold">Environment:</h3>
        <ReactMarkdown>{build.env}</ReactMarkdown>
      </div>
      <div className="mb-2 overflow-scroll">
        <h3 className="font-bold">Features</h3>
        <ol>
          {build.info.usesFeatures.map((i) => (
            <li key={i.name}>{i.name}</li>
          ))}
        </ol>
      </div>
      <div className="mb-2 overflow-scroll">
        <h3 className="font-bold">Permissions</h3>
        <ol>
          {build.info.usesPermissions.map((i) => (
            <li key={i.name}>{i.name}</li>
          ))}
        </ol>
      </div>

      <QRCode
        size={256}
        className="mx-auto w-full lg:hidden"
        value={window.location.href}
      />
    </>
  );
}

function Build({ projectId, buildId }) {
  const [build, setBuild] = useState(null);
  useEffect(() => {
    return db.doc(`/apps/${projectId}/builds/${buildId}`).onSnapshot({
      next: (snapshot) => {
        setBuild({
          ...snapshot.data(),
          id: snapshot.id,
        });
      },
    });
  }, [projectId, buildId]);
  if (!build) return null;

  const isIOS = build.platform === "iOS";
  return (
    <>
      <div className="flex-1">
        <div className="bg-blue py-8 text-white px-4 lg:px-0 text-white">
          <div className="flex flex-col items-stretch lg:flex-row lg:items-center container mx-auto lg:px-8">
            <div className="flex flex-row">
              <img
                className="mr-2 w-16 h-16"
                src={build.icon}
                alt={build.name}
              />
              <div>
                <h1 className="text-2xl font-medium">
                  {build.info.appName} ({build.version})
                </h1>
                <p>for {build.platform}</p>
              </div>
            </div>

            {isIOS ? (
              <a
                className="bg-red text-white px-4 py-2 cursor-pointer text-center mt-2 lg:mt-0 lg:ml-auto"
                href={`itms-services://?action=download-manifest&url=${encodeURIComponent(
                  `https://www.redsoapp.com/apps/${projectId}/builds/${build.id}/ios_manifest`
                )}`}
              >
                Install
              </a>
            ) : (
              <a
                className="bg-red text-white px-4 py-2 cursor-pointer text-center mt-2 lg:mt-0 lg:ml-auto"
                href={build.path}
              >
                Download
              </a>
            )}
          </div>
        </div>
        <div className="container mx-auto py-4 px-4 overflow-scroll lg:px-8">
          {isIOS ? (
            <IOSBuild build={build} projectId={projectId} />
          ) : (
            <AndroidBuild build={build} />
          )}
        </div>
      </div>
    </>
  );
}

export default Build;
