import { Link } from "@reach/router";
import { auth } from "./db";
import logo from "./logo.svg";
import { useAuthState } from "./auth-state";

function Header() {
  const { user, loading } = useAuthState();

  const logout = async () => {
    await auth().signOut();
  };
  return (
    <header className="flex flex-row items-center p-4 bg-blue border-b border-gray">
      <Link to="/">
        <img className="inline" src={logo} alt="Logo" />
      </Link>
      <div className="ml-auto">
        <Link to="/faq" className="text-white font-bold mr-8">
          FAQ
        </Link>
        {!loading &&
          (user ? (
            <button className="text-white font-bold" onClick={logout}>
              Logout
            </button>
          ) : (
            <Link to="/login" className="text-white font-bold">
              Login
            </Link>
          ))}
      </div>
    </header>
  );
}

export default Header;
