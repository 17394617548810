import { useEffect } from "react";
import { auth } from "./db";
import { navigate } from "@reach/router";

function FinishSignUp() {
  useEffect(() => {
    if (auth().isSignInWithEmailLink(window.location.href)) {
      var email = window.localStorage.getItem("emailForSignIn");
      if (!email) {
        email = window.prompt("Please provide your email for confirmation");
      }
      auth()
        .signInWithEmailLink(email, window.location.href)
        .then(function (result) {
          window.localStorage.removeItem("emailForSignIn");
          navigate("/");
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  });

  return <div>Loading</div>;
}

export default FinishSignUp;
