import { Redirect } from "@reach/router";
import { useAuthState } from "./auth-state";

const PrivateRoute = (props) => {
  const { user, loading } = useAuthState();

  let { as: Comp, ...restOfTheProps } = props;
  if (loading) {
    return <div>loading</div>;
  }
  return user ? (
    <Comp {...restOfTheProps} />
  ) : (
    <Redirect to="/login" replace={true} noThrow={true} />
  );
};

export default PrivateRoute;
