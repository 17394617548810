import React from "react";
import "./App.css";
import { Router } from "@reach/router";
import Build from "./Build";
import Builds from "./Builds";
import Projects from "./Projects";
import Login from "./Login";
import PostLogin from "./PostLogin";
import FinishSignUp from "./FinishSignUp";
import FAQ from "./FAQ";
import PrivateRoute from "./PrivateRoute";
import Header from "./Header";
import { AuthStateProvider } from "./auth-state";

function App() {
  return (
    <AuthStateProvider>
      <div className="App">
        <Header />
        <Router className="flex-1 flex flex-col overflow-scroll">
          <Login path="/login" />
          <PostLogin path="/postLogin" />
          <FAQ path="/faq" />
          <FinishSignUp path="/finishSignUp" />
          <Build path=":projectId/builds/:buildId" />
          <PrivateRoute as={Builds} path=":projectId/builds" />
          <Projects path="/" />
        </Router>
        <footer className="text-center bg-red text-white py-2">
          Created by RedSo
        </footer>
      </div>
    </AuthStateProvider>
  );
}

export default App;
