import QRCode from "qrcode.react";

function FAQ() {
  return (
    <>
      <div className="flex-1">
        <div className="bg-blue py-8 text-white px-4 lg:px-8">
          <div className="flex flex-row items-center container mx-auto">
            <QRCode
              size={64}
              bgColor="#00000000"
              fgColor="#FFFFFF"
              className="ml-auto"
              value={window.location.href}
            />
          </div>
        </div>
        <div className="container mx-auto py-4 px-4 lg:px-8">
          <h2 className="text-xl font-bold mb-4 text-red">Android</h2>
          <ul>
            <li className="mb-4">
              <p className="border-b border-blue mb-2 font-bold">
                While installing the app on Android, I get a security warning.
              </p>
              <div>
                <p className="mb-2">
                  On Android 8 or higher, you get a dialog such as "Chrome, For
                  your security, your phone is not allowed to install unknown
                  apps from this source". Click the <strong>Settings</strong>{" "}
                  button of that dialog and toggle{" "}
                  <strong>Allow from this source</strong>. Alternatively you can
                  navigate to{" "}
                  <strong>Phone Settings &gt; Apps &amp; notifications</strong>.
                  Find the browser application in the list, tap{" "}
                  <strong>Advanced</strong> then{" "}
                  <strong>Install unknown apps</strong> then toggle{" "}
                  <strong>Allow from this source</strong>.
                </p>
                <p className="mb-2">
                  On Android 7 or lower, navigate to the device{" "}
                  <strong>Settings</strong> then into the{" "}
                  <strong>Security</strong> category. Under the{" "}
                  <strong>Device Administration</strong> category, toggle{" "}
                  <strong>Unknown Sources</strong>. Then try to install the
                  application again.
                </p>
                <p>
                  Please note that some device manufacturers might customize the
                  settings UI and thus menu entries can have different names or
                  be at a different location.
                </p>
              </div>
            </li>
            <li className="mb-4">
              <p className="border-b border-blue mb-2 font-bold">
                While installing the app, I get a "There Was a Problem Parsing
                the Package" error.
              </p>
              <div>
                <p className="mb-2">
                  This error could be caused by one of the following reasons:
                </p>

                <ul className="list-disc list-inside mb-2">
                  <li>File may be downloaded incompletely</li>
                  <li>
                    Application might be not suitable for your hardware or OS
                    version
                  </li>
                  <li>Security settings</li>
                  <li>Corrupted APK file</li>
                  <li>Name of the package is changed after signing</li>
                </ul>

                <p>
                  To troubleshoot, try reinstalling the app. If that doesn't
                  work, contact the app developer to help resolve the issue.
                </p>
              </div>
            </li>
          </ul>
          <h2 className="text-xl font-bold mb-4 text-red">iOS</h2>
          <ul>
            <li className="mb-4">
              <p className="border-b border-blue mb-2 font-bold">
                I get the message "Untrusted Enterprise Developer".
              </p>
              <div>
                <p>
                  In order for the app to be available for use, you will need to
                  trust the developer's certificate. From your phone’s home
                  screen, tap{" "}
                  <strong>
                    Settings &gt; General &gt; Profiles or Profiles &amp; Device
                    Management
                  </strong>
                  . Under the <strong>Enterprise App</strong> heading, you see
                  will see a profile for the developer. Tap the name of the
                  developer profile and then confirm you trust them. You should
                  now be able to launch the app.
                </p>
              </div>
            </li>
            <li className="mb-4">
              <p className="border-b border-blue mb-2 font-bold">
                While installing the app, I get an "Unable to Download App"
                error, and the app is not installed.
              </p>
              <div>
                <p className="mb-2">
                  This error could be caused by many reasons, and you should
                  contact the app developer to help resolve the issue. If you
                  are the app developer, this error could be caused by one of
                  the following reasons:
                </p>

                <ul className="list-disc list-inside mb-2">
                  <li>UDID is not included in the provisioning profile</li>
                  <li>
                    The build was incorrectly signed or has broken entitlements
                  </li>
                  <li>Device or iOS version is incompatible with the build</li>
                  <li>Device storage is full</li>
                  <li>Actual download error</li>
                  <li>
                    App is over 50 MB and downloaded over a cellular connection
                  </li>
                </ul>
              </div>
            </li>
            <li className="mb-4">
              <p className="border-b border-blue mb-2 font-bold">
                After installing the app, It appears to download successfully
                but I am unable to open it.
              </p>
              <div>
                <p>
                  If you are a tester, this could be because you haven't
                  registered your device. From the install page in safari, tap
                  Profile in the top right, register your device and uninstall
                  the existing version of the app. If you are downloading a
                  public release or registering your device did not help with
                  your installation issues, contact your app developer.
                </p>
              </div>
            </li>
            <li>
              <p className="border-b border-blue mb-2 font-bold">
                When I press the "Install" button, the installation alert never
                shows up and the app never installs on my device.
              </p>
              <div>
                <p>
                  If you have previously installed the app from the App Store,
                  and it carries the same version of the app that is being
                  installed, there will be no alert to confirm the installation.
                  As a workaround, please try uninstalling the existing version
                  of the app, and then tap the Install button again.
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default FAQ;
