import React, { useEffect, useState } from "react";
import QRCode from "qrcode.react";
import { db } from "./db";
import { Link } from "@reach/router";
import { useThrottle } from "@react-hook/throttle";

function Projects() {
  const [searchTerm, setSearchTerm] = useThrottle("", 3);
  const [projects, setProjects] = useState([]);
  const [error, setError] = useState(null);
  useEffect(() => {
    return db
      .collection(`/apps`)
      .orderBy("name")
      .startAt(searchTerm)
      .endAt(searchTerm + "\uf8ff")
      .onSnapshot({
        error: (error) => {
          if (error && error.code === "permission-denied") {
            setError(error);
          }
        },
        next: (snapshot) => {
          const data = [];
          setError(null);
          snapshot.forEach((s) => {
            data.push({
              ...s.data(),
              id: s.id,
            });
          });
          setProjects(data);
        },
      });
  }, [searchTerm]);
  if (error) {
    return (
      <>
        <div className="flex-1">
          <div className="bg-blue py-8 text-white px-4 lg:px-0">
            <div className="flex flex-row items-center container mx-auto lg:px-8">
              <QRCode
                size={64}
                bgColor="#00000000"
                fgColor="#FFFFFF"
                className="ml-auto"
                value={window.location.href}
              />
            </div>
          </div>
          <div className="container mx-auto py-4 px-4 lg:px-0">
            Permission Denied
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <div className="flex-1">
        <div className="bg-blue py-8 text-white px-4 lg:px-0">
          <div className="flex flex-row items-center container mx-auto lg:px-8">
            <QRCode
              size={64}
              bgColor="#00000000"
              fgColor="#FFFFFF"
              className="ml-auto"
              value={window.location.href}
            />
          </div>
        </div>
        <div className="container mx-auto py-4 px-4 lg:px-8">
          <div>
            <input
              className="w-full border-b border-blue mb-4 text-xl p-2"
              onChange={(e) => setSearchTerm(e.target.value)}
            ></input>
          </div>

          {projects.map((project) => (
            <Link
              key={project.id}
              className="flex flex-row items-center shadow p-4 mb-4"
              to={`/${project.id}/builds`}
            >
              <div className="text-2xl font-medium">{project.name}</div>
            </Link>
          ))}
        </div>
      </div>
    </>
  );
}

export default Projects;
