function PostLogin() {
  return (
    <>
      <div className="container mx-auto py-4 px-4 items-center justify-center flex flex-1 lg:px-8">
        <p>Please check your email</p>
      </div>
    </>
  );
}

export default PostLogin;
